import { Router, RouteComponentProps } from "@reach/router";
import * as React from "react";

import PrivateRoute from "../PrivateRoute";
// import { Role } from '../../constants/Roles';

// import Baseapp from "../../partials/Baseapp";
import CoreEngines from "../../partials/Core-Engine/CoreEngines";
import CoreEngineNew from "../../partials/Core-Engine/CoreEngineNew";
import CoreEngineDetail from "../../partials/Core-Engine/CoreEngineDetail";
// import CoreEnginesPartial from "../../partials/CoreEnginesPartial";

import Dashboard from "../../partials/Dashboard/Dashboard";

import FeatureList from "../../partials/Feature/Features";
import FeaturesNew from "../../partials/Feature/FeaturesNew";
import featureDetail from "../../partials/Feature/FeaturesDetail";

import Login from "../../partials/Login/Login";
import ChangePassword from "../../partials/ChangePassword/ChangePassword";

import BuildList from "../../partials/Builds/Builds";
import BuildsDetails from "../../partials/Builds/BuildsDetails";

import Products from "../../partials/Products/Products";
import ProductsDetail from "../../partials/Products/ProductsDetail";
import ProductsNew from "../../partials/Products/ProductsNew";

import ApplicationNew from "../../partials/Application/ApplicationNew";
import ApplicationDetail from "../../partials/Application/ApplicationDetail";

import TestEngineNew from "../../partials/Test-Engine/TestEngineNew";
import TestEngineDetail from "../../partials/Test-Engine/TestEngineDetail";

import UserList from "../../partials/User/Users";
import UserNew from "../../partials/User/UsersNew";
import userDetail from "../../partials/User/UsersDetail";
import Documents from "../../partials/Documents";

import LimitedAccess from "../../partials/OtherPages/LimitedAccess";
import NotFoundPage from "../../partials/OtherPages/NotFoundPage";

import Layout from "../../widgets/Layout";

const AppPage = () => {
  return (
    <Layout>
      <Router basepath={"/app"}>
        <PrivateRoute
          component={Dashboard}
          path="/"
          accessNeeded={{ module: "dashboard", operation: "view" }}
        />

        <PrivateRoute
          component={Documents}
          path="/document"
          accessNeeded={{ module: "document", operation: "view" }}
        />

        <PrivateRoute
          component={FeatureList}
          path="/features"
          accessNeeded={{ module: "feature", operation: "view" }}
        />
        <PrivateRoute
          component={FeaturesNew}
          path="/features/new"
          accessNeeded={{ module: "feature", operation: "add" }}
        />
        <PrivateRoute
          component={featureDetail}
          path="/features/:id"
          accessNeeded={{ module: "feature", operation: "viewdetails" }}
        />

        <PrivateRoute
          component={CoreEngines}
          path="/core-engines"
          accessNeeded={{ module: "coreengine", operation: "view" }}
        />
        <PrivateRoute
          component={CoreEngineNew}
          path="/core-engines/new"
          accessNeeded={{ module: "coreengine", operation: "add" }}
        />
        <PrivateRoute
          component={CoreEngineDetail}
          path="/core-engines/:engine_key"
          accessNeeded={{ module: "coreengine", operation: "viewdetails" }}
        />

        <PrivateRoute
          component={Products}
          path="/products"
          accessNeeded={{ module: "products", operation: "view" }}
        />
        <PrivateRoute
          component={ProductsDetail}
          path="/products/:prodkey"
          accessNeeded={{ module: "products", operation: "viewdetails" }}
        />
        <PrivateRoute
          component={ProductsNew}
          path="/products/new"
          accessNeeded={{ module: "products", operation: "add" }}
        />

        <PrivateRoute
          component={ApplicationNew}
          path="/products/product-apps/new"
          accessNeeded={{ module: "products_apps", operation: "add" }}
        />
        <PrivateRoute
          component={ApplicationDetail}
          path="/products/product-apps/:app_key"
          accessNeeded={{ module: "products_apps", operation: "viewdetails" }}
        />

        <PrivateRoute
          component={TestEngineNew}
          path="/products/test-engines/new"
          accessNeeded={{
            module: "products_apps_testengine",
            operation: "add",
          }}
        />
        <PrivateRoute
          component={TestEngineDetail}
          path="/products/test-engines/:test_engine_id"
          accessNeeded={{
            module: "products_apps_testengine",
            operation: "viewdetails",
          }}
        />

        <PrivateRoute
          component={BuildList}
          path="/builds"
          accessNeeded={{ module: "builds", operation: "view" }}
        />
        <PrivateRoute
          component={BuildsDetails}
          path="/builds/:appkey"
          accessNeeded={{ module: "builds", operation: "viewdetails" }}
        />
        <PrivateRoute
          component={BuildsDetails}
          path="/builds/new"
          accessNeeded={{ module: "builds", operation: "add" }}
        />

        <PrivateRoute
          component={UserList}
          path="/users"
          accessNeeded={{ module: "user", operation: "view" }}
        />
        <PrivateRoute
          component={UserNew}
          path="/users/new"
          accessNeeded={{ module: "user", operation: "add" }}
        />
        <PrivateRoute
          component={userDetail}
          path="/users/:user_name"
          accessNeeded={{ module: "user", operation: "viewdetails" }}
        />

        <RouterPage path="/login" pageComponent={<Login />} />
        <RouterPage path="/no-access" pageComponent={<LimitedAccess />} />
        <RouterPage default={true} pageComponent={<NotFoundPage />} />

        <PrivateRoute
          component={ChangePassword}
          path="/change-password"
          accessNeeded={{ module: "user", operation: "changePassword" }}
        />
        {/* <NotFoundPage  /> */}
        {/* <PrivateRoute component={Baseapp}  path="/" /> */}
        {/* <Login  path="/login" />
          <LimitedAccess path="/no-access" />
          <NotFoundPage path="/not-found" /> */}
      </Router>
    </Layout>
  );
};

export default AppPage;

const RouterPage = (
  props: { pageComponent: JSX.Element } & RouteComponentProps
) => props.pageComponent;
