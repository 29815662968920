import React, { useState } from "react";
import { navigate } from "@reach/router";
// import { isLoggedIn } from "../../_services/auth1"

import { AuthService } from "../../_services/auth.service";

import CardComponent from "../../widgets/Card/Card-Component";
import Form from "./Form";
import View from "../../widgets/LoginView";
import { Card } from "react-bootstrap";
import Loader from "../../widgets/loader/Loader";
import ErrorPopup from "../../widgets/Popups/Error-Popup";
import { useStoreState, useStoreActions } from "easy-peasy";

const Login = () => {
  const stateStore = useStoreState((state) => state?.auth?.userData);
  const stateAction = useStoreActions((actions) => actions?.auth?.setAuth);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
  });

  const [productList, setProductList] = useState(null);
  const [state, setState] = useState({
    username: ``,
    password: ``,
  });
  const [isValidform, setIsValid] = useState(false);

  React.useEffect(() => {
    if (AuthService.isLoggedIn()) {
      navigate(`/app`);
    }
  }, []);

  function handleUpdate(event) {
    setState({ ...state, [event.target.name]: event.target.value });
    onValid(event);
  }

  function handleSubmit(event) {
    setLoading(true);
    event.preventDefault();
    if (state.username != "" && state.password != "") {
      AuthService.login(state.username, state.password).then(
        async (res) => {
          if (res.status == 200) {
            let user = {
              username: res.username,
            };
            stateAction(user);
            setLoading(false);
            navigate(`/app/builds`);
          } else {
            let msg = {
              title: "Error",
              message: ""
            };
            if(res?.status=="408"){
              msg.message = res?.data
            }else{
              msg.message = res?.detail
            }
        
            setError({
              ...error,
              errorStatus: true,
              errorData: JSON.stringify(msg),
            });
            setLoading(false);
          }
        },
        (error) => {
             const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          let msg = {
            title: "Error",
            message: resMessage,
          };
          setError({
            ...error,
            errorStatus: true,
            errorData: JSON.stringify(msg),
          });
          setLoading(false);
          // const resMessage =
          //   (error.response &&
          //     error.response.data &&
          //     error.response.data.message) ||
          //   error.message ||
          //   error.toString();

          // this.setState({
          //   loading: false,
          //   message: resMessage
          // });
        }

        //     resp => {

        //     //
        //     // if(resp.status == 200){
        //     //     // getUser(resp.data.username).then(resp => {
        //     //     // handleLogin(resp.data, state)
        //     //     // //
        //     //     // setLoading(false)
        //     //     // navigate(`/app`)
        //     // }else{
        //     //     // //
        //     let msg = {
        //         title : "Error",
        //         // description : "Enter Valid Username Password.",
        //         message : "Please enter valid Username and password."
        //     }
        //     setError({...error, errorStatus : true, errorData : JSON.stringify(msg)})
        //     //     setLoading(false)
        //     // }
        // }
      );
    } else {
      setLoading(false);
    }
  }

  function onValid(val) {
    // const rows = [...state.username];
    // const rows1 = [...state.password];
    // //
    const { username, password } = state;
    username && password && val.target.value != ""
      ? setIsValid(true)
      : setIsValid(false);
  }

  function closeError() {
    setError({ ...error, errorStatus: false });
  }

  return (
    <View>
      {loading ? <Loader /> : ""}
      {error.errorStatus ? (
        <ErrorPopup data={error.errorData} closePopup={closeError} />
      ) : (
        ""
      )}
      <CardComponent nameofclass="bg-white custom-card text-center p-3 p-lg-5 rounded">
        <Card.Title>
          <h2>Login</h2>
        </Card.Title>
        <Card.Body className="px-0">
          {/* <p>For this demo, please log in with the username <code>superadmin</code> and the password <code>erebus</code></p> */}
          <Form
            handleUpdate={handleUpdate}
            handleSubmit={handleSubmit}
            isValidform={isValidform}
          />
        </Card.Body>
      </CardComponent>
    </View>
  );
};

export default Login;
