import api from "./api";

export const buildsService = {
  getAppBuilds,
  getAppBuildDetail,
  createAppBuild,
  getTestBuilds,
  getTestBuildDetail,
  getCoreEngines,
};

function getAppBuilds(req) {
  let appKey = req.application;
  let requestor = req.by_requestor_id
    ? "requestor_id=" + req.requestor_id + "&"
    : "";
  let q = req.q ? "q=" + req.q + "&" : "";
  let failedonly = req.failedOnly ? "failed_only=" + req.failedOnly + "&" : "";
  let lm = req.limit ? "limit=" + req.limit + "&" : "";
  let offs = req.offset ? "offset=" + req.offset : "offset=0";
  return api.get(
    `api/applications/${appKey}/app-builds?${requestor}${q}${failedonly}${lm}${offs}`
  );
}

function getAppBuildDetail(appKey) {
  return api.get(`api/app-builds/${appKey}`);
}

function createAppBuild(data: any, appKey: any, authKey: any) {
  return api
    .post(`api/applications/${appKey}/app-builds/${authKey}`, {
      ...data,
      is_errored: false,
      error_message: "string",
    })
    .then((response) => {
      return response;
    });
  // return fetch(`${process.env.API_URL}api/applications/${appKey}/app-builds/${authKey}`, requestOptions).then(handleResponse);
}

function getTestBuilds(id) {
  return api.get(`api/app-builds/${id}/test-builds`);
}

function getTestBuildDetail(id) {
  return api.get(`api/test-builds/${id}`);
}

function getCoreEngines(param) {
  return api.get(`api/applications/${param}/test-engines`);
}
